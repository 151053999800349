/**************************************************
 * Nombre:       Detalle_Producto_Detalle
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import bolsa from '../../../Recursos/ic_bolso.svg'
import {theme} from "../../../Tema";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {PRODUCTOS} from "../../../Constante";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {marcarEvento} from "../../../Servicios/Analiticas/marcarEvento";


const Detalle_Producto_Detalle = () => {
    const {sCell} = useResponsive()
    const parms = useParams()
    const [producto, setProducto] = useState({})

    useEffect(() => {
        if (parms?.id) {
            let index = parseInt(parms.id)
            setProducto(PRODUCTOS[index])
            marcarEvento(PRODUCTOS[index].nombre + '_detalle')
        }
    }, [parms]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >
                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={6}
                    >

                        <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{color: '#004438', fontWeight: 800, fontSize: sCell ? 32 : 42}}>
                                        {producto.nombre}
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Typography sx={{color: '#004438', fontWeight: 400, fontSize: sCell ? 16 : 18}}>
                                        {producto.descripcion}
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{
                                          justifyContent: 'flex-end',
                                          backgroundColor: theme.palette.primary.main,
                                          pl: 3,
                                          py: 1,
                                          borderRadius: 6,
                                          marginTop: 4
                                      }}>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >

                                        <Grid item container lg={3} sm={12} xs={3} sx={{justifyContent: 'flex-start'}}>
                                            <img src={bolsa} width={22} height={'auto'}

                                            />
                                        </Grid>

                                        <Grid item container lg={6} sm={12} xs={6} sx={{
                                            justifyContent: 'center', borderRight: 1,
                                            borderColor: '#fff',
                                        }}>
                                            < Typography sx={{
                                                color: '#fff'
                                            }}>Arroba ( 25 Libra )</Typography>
                                        </Grid>


                                        <Grid item container lg={3} sm={12} xs={3} sx={{justifyContent: 'center'}}>
                                            <Typography sx={{color: '#fff'}}> 1 Libra</Typography>
                                        </Grid>


                                    </Grid>


                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                    sx={{marginTop: 3}}
                                >

                                    <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: 'center'}}>
                                        <img src={producto.img_empaque} width={'auto'} height={sCell ? 180 : 210}/>
                                    </Grid>

                                    <Grid item container lg={4} sm={4} xs={4}
                                          sx={{justifyContent: 'center'}}>
                                        <img src={producto.img_empaque} width={'auto'} height={sCell ? 180 : 210}/>
                                    </Grid>

                                    <Grid item container lg={4} sm={4} xs={4}
                                          sx={{justifyContent: 'center'}}>
                                        <img src={producto.img_empaque} width={'auto'} height={sCell ? 180 : 210}/>
                                    </Grid>


                                </Grid>


                            </Grid>
                        </Grid>

                        {!sCell &&
                            <Grid item container lg={7} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <img src={producto.img} width={'100%'}
                                     style={{borderRadius: 32,}}/>
                            </Grid>
                        }

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Detalle_Producto_Detalle