/**************************************************
 * Nombre:       BarraPc
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, ButtonBase, Grid, Typography} from "@mui/material";
import logo from '../../Recursos/logo_blanco.svg'
import {SECCIONES} from "../../Constante";
import {theme} from "../../Tema";
import {useNavigate} from "react-router-dom";

const BarraPc = ({barraVerde}) => {
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: barraVerde ? '#004438' : ''}}
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{maxWidth: '1400px', px: 3, py: 3}}
            >

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <img src={logo} width={170} height={'auto'}
                         onClick={() => navigate('/Home')}
                         style={{cursor: 'pointer'}}
                    />
                </Grid>

                <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'flex-end'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >

                        {SECCIONES.map((item, index) => {
                            return (
                                <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'center'}}>
                                    <ButtonBase
                                        onClick={() => navigate('/' + item)}
                                    >
                                        <Typography sx={{
                                            color: '#fff',
                                            fontWeight: 600,
                                            borderBottom: 2,
                                            borderColor: '#ffffff00',
                                            transition: 'all .2s ease-in-out',
                                            '&:hover': {
                                                borderColor: '#fff'
                                            }

                                        }}>{item}</Typography>
                                    </ButtonBase>
                                </Grid>
                            )
                        })}


                    </Grid>
                </Grid>

                <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: 'flex-end'}}>
                    <Button
                        onClick={() => navigate('/Estado')}
                        variant={'contained'} color={'info'}
                            sx={{
                                color: theme.palette.primary.main,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                    color: '#fff'
                                },
                                boxShadow: 0

                            }}
                    >Estado de
                        Pedido</Button>
                </Grid>


            </Grid>

        </Grid>
    )

}
export default BarraPc    