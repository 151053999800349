/**************************************************
 * Nombre:       Portada_Detalle_Equipo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import Tarjeta_Andres from "./SubComponentes/Tarjeta_Andres";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import Tarjeta_Nataly from "./SubComponentes/Tarjeta_Nataly";
import Tarjeta_Oscar from "./SubComponentes/Tarjeta_Oscar";
import {EQUIPOTRABAJO} from "../../../Constante";
import {irUrl} from "../../../Utilidades/irUrl";

const Portada_Detalle_Equipo = ({persona}) => {
    const {sCell, masSM, sTab} = useResponsive()

    const obtenerTarjeta = () => {

        switch (persona.nombre) {
            case EQUIPOTRABAJO[0].nombre :
                return <Tarjeta_Andres acolor={true}/>
            case EQUIPOTRABAJO[1].nombre :
                return <Tarjeta_Oscar acolor={true}/>
            case EQUIPOTRABAJO[2].nombre :
                return <Tarjeta_Nataly acolor={true}/>
        }

    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >
                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: 'flex-start', marginLeft: !masSM ? -8 : 0}}>
                            {obtenerTarjeta()}
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 6 : 0, pl: sTab ? 6 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography
                                        sx={{
                                            color: '#00413B',
                                            fontSize: sCell ? 32 : 40,
                                            fontWeight: 600,
                                            lineHeight: 1.1
                                        }}>
                                        {persona.nombre}
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Typography
                                        sx={{color: '#00413B', fontSize: 18, fontWeight: 400}}>
                                        {persona.descripcion}
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                    <Button
                                        onClick={() => irUrl(persona.link)}
                                    > Ver Linkedin </Button>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Portada_Detalle_Equipo