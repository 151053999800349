import insta from './Recursos/insta.svg'
import face from './Recursos/face.svg'
import tiktok from './Recursos/tiktok.svg'
import lenteja_constal from './Recursos/lentejas.png'
import lenteja_empaque from './Recursos/Lenjeta.png'
import arroz from './Recursos/arroz.png'
import arroz_empaque from './Recursos/Arroz_paq.png'
import frijol_constal from './Recursos/frijol.png'
import frijol_blanco_constal from './Recursos/Frijol_Blanquillo_Costal.png'
import frijol_bolon_constal from './Recursos/Frijol_Bolon_Costal.png'
import frijol_empaque from './Recursos/Frijol_paq.png'
import frijol_bolon from './Recursos/Frijol_Bolon.png'
import frijol_blanquillo from './Recursos/Frijol_Blanquillo.png'
import {Mail, Place, WhatsApp} from "@mui/icons-material";
import andres from './Recursos/andres_buraz.png'
import oscar from './Recursos/oscar_buraz.png'
import nata from './Recursos/nataly_buraz.png'

export const SECCIONES = ['Home', 'Nosotros', 'Directorio']

export const NUMEROWASS = '3104210620'

export const REDES = [
    {
        icono: insta,
        nombre: 'Instagram',
        url: 'https://www.instagram.com/grupo.buraz/'
    },
    {
        icono: face,
        nombre: 'Facebook',
        url: 'https://www.facebook.com/profile.php?id=61560936483596'
    },
    {
        icono: tiktok,
        nombre: 'Facebook',
        url: 'https://www.tiktok.com/@grupo.buraz'
    },
]


export const CONTACTOS = [
    {
        icono: WhatsApp,
        nombre: 'celular',
        descripcion: '316 717 2628',
        url: ''
    },
    {
        icono: Mail,
        nombre: 'correo',
        descripcion: 'info@grupoburaz.com',
        url: ''
    },
    {
        icono: 'Place',
        nombre: 'direccion uno',
        descripcion: '',
        url: ''
    },
    {
        icono: Place,
        nombre: 'direccion dos',
        descripcion: 'Ipiales - Nariño: Calle 15 # 10-21',
        url: ''
    },

]

export const PRODUCTOS = [
    {
        id: 'Arroz',
        nombre: 'Arroz',
        descripcion: 'Un básico infaltable en cualquier punto de venta, con granos seleccionados que aseguran calidad y frescura constante. Garantizamos un producto confiable para sus clientes.',
        img: arroz,
        img_empaque: arroz_empaque

    },
    {
        id: 'Lenteja',
        nombre: 'Lenteja',
        descripcion: 'Producto de alta rotación y calidad superior, ideal para satisfacer la demanda de consumidores que buscan opciones nutritivas y saludables. Perfecta para ampliar su portafolio de granos secos.',
        img: lenteja_constal,
        img_empaque: lenteja_empaque

    },
    {
        id: 'Frijol',
        nombre: 'Frijol',
        descripcion: 'Grano esencial con alta demanda en el mercado, ofreciendo un sabor auténtico y una calidad que sus clientes apreciarán. Una opción segura para fortalecer su oferta de productos básicos.',
        img: frijol_constal,
        img_empaque: frijol_empaque

    },
    {
        id: 'Frijol Bolon',
        nombre: 'Frijol Bolon',
        descripcion: 'Grano de gran tamaño con un sabor suave y textura cremosa. Perfecto para sopas y guisos, garantizando frescura y un alto valor nutritivo para sus clientes.',
        img: frijol_bolon_constal,
        img_empaque: frijol_bolon

    },
    {
        id: 'Blanquillo',
        nombre: 'Blanquillo',
        descripcion: 'Un frijol de color claro y textura suave, ideal para diversas preparaciones. Su alta calidad y demanda lo convierten en una excelente opción para su punto de venta.',
        img: frijol_blanco_constal,
        img_empaque: frijol_blanquillo

    },
]


export const EQUIPOTRABAJO = [
    {
        nombre: 'Andrés Arévalo',
        descripcion: 'El conocimiento y experiencia de Andrés Arévalo en gestión estratégica son fundamentales para la toma de decisiones clave en Buraz. Su capacidad para evaluar y optimizar el rendimiento económico de la empresa refuerza nuestra competitividad en el mercado, asegurando una operación sólida y eficiente. Con su enfoque analítico y experiencia en instituciones clave, Nathaly contribuye a posicionar a Buraz como la mejor elección para distribuidores que buscan estabilidad, crecimiento y respaldo profesional en sus negocios. Con cada grano que elegimos, cultivamos confianza para tu hogar y tu negocio.',
        link: 'https://www.linkedin.com/in/andres-ar%C3%A9valo-zambrano-540756157?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
        img: andres
    }, {
        nombre: 'Oscar Burbano',
        descripcion: 'La trayectoria y experiencia de trabajo en el sector de los granos y cereales le han ayudado a Óscar Burbano a identificar productos de calidad, a conocer el proceso logístico y entender la cadena de valor del trabajo en Buraz. Asimismo,  el conocimiento en el área comercial y administrativo aportan valor a la compañía para crear un equipo íntegro.',
        link: 'https://www.linkedin.com/in/%C3%B3scar-hernando-burbano-ar%C3%A9valo-82a44b194/',
        img: oscar
    },
    {
        nombre: 'Nathaly Triana',
        descripcion: 'El profundo conocimiento de Nathaly Triana en análisis de mercado fortalece la visión de Buraz, garantizando decisiones acertadas y un enfoque centrado en la calidad y la satisfacción del cliente. Su experiencia asegura una operación eficiente y una capacidad de adaptación que posiciona a Buraz como la mejor elección para distribuidores que buscan productos confiables y un respaldo sólido en el mercado colombiano.',
        link: 'https://www.linkedin.com/in/nathaly-triana?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
        img: nata
    },
]




