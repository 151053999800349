/**************************************************
 * Nombre:       Seccion_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Llevalo_Casa from "./Componentes/Llevalo_Casa";
import Llevalo_Negocio from "./Componentes/Llevalo_Negocio";
import Frase_Humanizadora from "./Componentes/Frase_Humanizadora";
import Detalle_Producto_Detalle from "./Componentes/Detalle_Producto_Detalle";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useEffect} from "react";
import {irArriba} from "../../Utilidades/irArriba";

const Seccion_Detalle_Producto = () => {
    const {sCell} = useResponsive()

    useEffect(() => {
        irArriba()
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 4 : 11}}>
                <Detalle_Producto_Detalle/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: sCell ? 10 : 14}}>
                <Llevalo_Negocio/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                <Llevalo_Casa/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                <Frase_Humanizadora/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Detalle_Producto