/**************************************************
 * Nombre:       Llevalo_Casa
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import vendedor from '../../../Recursos/para_negocio.png'
import ramas from '../../../Recursos/ramas.png'
import {theme} from "../../../Tema";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {WhatsApp} from "@mui/icons-material";
import {useDialogoWhatsApp} from "../../Hooks/useDialogoWhatsApp";


const Llevalo_Negocio = () => {
    const {masSM, sCell, sTab} = useResponsive()
    const {Dialogo, abrir} = useDialogoWhatsApp()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{overflowX: 'hidden'}}
        >
            <Grid
                container
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: '1400px', px: 3}}
                spacing={1}
            >

                <Dialogo/>

                {masSM &&
                    <Grid item container lg={1} sm={1} xs={12}
                          sx={{justifyContent: 'flex-start', ml: -25, marginRight: 25}}>
                        <img src={ramas} width={'300%'} height={'auto'}/>
                    </Grid>
                }

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                    <img src={vendedor} width={sCell ? '120%' : '90%'} height={'auto'}
                         style={{borderRadius: 40, marginLeft: sCell ? -98 : 0}}/>
                </Grid>

                <Grid item container lg={5} sm={5} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{marginLeft: sTab ? -4 : 0,}}

                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography
                                sx={{
                                    fontSize: !masSM ? 32 : 40, fontWeight: 600, color: theme.palette.secondary.main,
                                    textAlign: sCell ? 'center' : 'left',
                                    lineHeight: 1.1
                                }}>
                                Llévalo para tu negocio
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{
                                  justifyContent: 'flex-start', marginTop: sCell ? 2 : 1
                              }}>
                            <Typography sx={{
                                fontSize: 18,
                                fontWeight: 400,
                                color: '#00413B',
                                mr: sCell ? 0 : -4,

                                textAlign: sCell ? 'center' : 'left'
                            }}>
                                Comprar nuestros productos para tu negocio te garantiza alta calidad a precios
                                competitivos. Esto te permite maximizar tus ganancias y ofrecer a tus clientes alimentos
                                seguros y confiables, ayudando a que tu negocio se destaque.
                            </Typography>
                        </Grid>

                        {/*  <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 3}}>
                            <Button
                                fullWidth={sCell}
                                color={'secondary'} sx={{color: '#fff'}}>Obtener Catálogo</Button>
                        </Grid>*/}

                        <Grid item container lg={6} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 2 : 3}}>
                            <Button
                                onClick={() => abrir()}
                                fullWidth={sCell}
                                variant={'outlined'} color={'secondary'}
                                startIcon={<WhatsApp/>}
                            >Hablar con un asesor</Button>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Llevalo_Negocio