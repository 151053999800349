/**************************************************
 * Nombre:       Llevalo_Casa
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import cocinando from '../../../Recursos/cocinando.png'
import tason from '../../../Recursos/tason_arroz.png'
import {theme} from "../../../Tema";
import Botones_Ciudades from "./SubComponentes/Botones_Ciudades";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {useContext} from "react";
import {contextPag} from "../../../App";


const Llevalo_Casa = () => {
    const {sCell, masSM} = useResponsive()
    const cData = useContext(contextPag)

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{overflowX: 'hidden'}}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: '1400px', px: 3}}
                spacing={sCell ? 0 : 1}
            >


                <Grid item container lg={5} sm={6} xs={12} sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                    <img src={cocinando} width={'90%'} height={'auto'} style={{borderRadius: 40}}/>
                </Grid>

                <Grid item container lg={5} sm={6} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: sCell ? 4 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"

                    >

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                            <Typography sx={{
                                fontSize: !masSM ? 32 : 40,
                                fontWeight: 600,
                                color: theme.palette.primary.main,
                                textAlign: sCell ? 'center' : 'left',
                                lineHeight: 1.1
                            }}>
                                Llévalo para
                                tu casa</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 1}}>
                            <Typography sx={{
                                fontSize: 18,
                                fontWeight: 400,
                                color: '#00413B',
                                mr: !masSM ? 0 : -4,
                                textAlign: sCell ? 'center' : 'left',
                                marginTop: !masSM ? 2 : 0
                            }}>
                                Al elegirnos aseguras calidad y frescura para tu familia. Nuestros productos son
                                nutritivos y ofrecen una excelente relación calidad-precio, brindándote la tranquilidad
                                de alimentar a tus seres queridos con lo mejor.
                            </Typography>
                        </Grid>

                        <Grid item container lg={10} sm={12} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: 2}}>
                            <Botones_Ciudades items={cData.departamentos}/>
                        </Grid>


                    </Grid>
                </Grid>

                {masSM &&
                    <Grid item container lg={2} sm={1} xs={12}
                          sx={{justifyContent: 'flex-start', ml: 8, marginRight: -20}}>
                        <img src={tason} width={'170%'} height={'auto'}/>
                    </Grid>
                }

            </Grid>


        </Grid>
    )

}
export default Llevalo_Casa