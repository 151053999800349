/**************************************************
 * Nombre:       Seccion_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Portada_Home from "./Componentes/Portada_Home";
import Llevalo_Casa from "./Componentes/Llevalo_Casa";
import Llevalo_Negocio from "./Componentes/Llevalo_Negocio";
import Precios_Competitivos from "./Componentes/Precios_Competitivos";
import Sectores_Mapa from "./Componentes/Sectores_Mapa";
import Contactanos from "./Componentes/Contactanos";
import Frase_Humanizadora from "./Componentes/Frase_Humanizadora";
import Nosotros_Home from "./Componentes/Nosotros_Home";
import Tienda_Frutos_Secos_Home from "./Componentes/Tienda_Frutos_Secos_Home";
import Equipo_Trabajo from "./Componentes/Equipo_Trabajo";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useEffect} from "react";
import {irArriba} from "../../Utilidades/irArriba";

const Seccion_Home = () => {
    const {sCell} = useResponsive()

    useEffect(() => {
        irArriba()
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -15}}>
                <Portada_Home/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 48 : 30}}>
                <Llevalo_Casa/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 14}}>
                <Llevalo_Negocio/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 14}}>
                <Precios_Competitivos/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 12}}>
                <Sectores_Mapa/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 12}}>
                <Contactanos/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Frase_Humanizadora/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                <Nosotros_Home/>
            </Grid>



        </Grid>
    )

}
export default Seccion_Home    