/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, IconButton, Typography} from "@mui/material";
import fondo from '../Recursos/fondo_footer.svg'
import logo from '../Recursos/logo_blanco.svg'
import {CONTACTOS, REDES, SECCIONES} from "../Constante";
import {irUrl} from "../Utilidades/irUrl";
import {useResponsive} from "../Modulo_responsive/Hooks/useResponsive";
import {useDialogoWhatsApp} from "./Hooks/useDialogoWhatsApp";
import {useNavigate} from "react-router-dom";

const Footer = () => {
    const {sCell} = useResponsive()
    const navigate = useNavigate()
    const {Dialogo, abrir} = useDialogoWhatsApp()


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{background: `url(${fondo})`, backgroundSize: 'cover'}}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3, py: 8}}
            >

                <Dialogo/>

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >

                                <Grid item container lg={5} sm={5} xs={6}
                                      sx={{justifyContent: 'flex-end', borderRight: 1, borderColor: '#fff', px: 4}}>
                                    <img src={logo} width={sCell ? '100%' : 200} height={'auto'}/>
                                </Grid>

                                <Grid item container lg={3} sm={3} xs={6}
                                      sx={{justifyContent: 'flex-start', px: 4, pr: sCell ? 0 : 4}}>
                                    <Typography sx={{color: '#fff', fontSize: sCell ? 16 : 18}}>Asesoria
                                        personalizada</Typography>
                                </Grid>

                                <Grid item container lg={2} sm={2} xs={12}
                                      sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: sCell ? 4 : 0}}>
                                    <Button
                                        onClick={() => abrir()}
                                    >Iniciar Chat</Button>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={10} sm={10} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >

                                <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: 'center'}}>
                                    <Typography sx={{color: '#fff', fontSize: 16, fontWeight: 600}}>Link
                                        Rapidos:</Typography>
                                </Grid>

                                {SECCIONES.map((item, index) => {
                                    return (
                                        <Grid item container lg={2} sm={2} xs={12}
                                              sx={{justifyContent: 'center', marginTop: sCell ? 2 : 0}}>
                                            <Typography
                                                onClick={() => navigate('/' + item)}
                                                sx={{color: '#fff', fontSize: 16, fontWeight: 400, cursor: 'pointer'}}>
                                                {item}
                                            </Typography>
                                        </Grid>
                                    )
                                })}

                                <Grid item container lg={2} sm={2} xs={12}
                                      sx={{justifyContent: sCell ? 'center' : 'flex-end', marginTop: sCell ? 4 : 0}}>
                                    {REDES.map((item, index) => {
                                        return (
                                            <Grid item container lg={3} sm={4} xs={2}
                                                  sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                                <IconButton
                                                    onClick={() => irUrl(item.url)}
                                                >
                                                    <img src={item.icono} style={{width: 32, height: 'auto'}}/>
                                                </IconButton>
                                            </Grid>
                                        )
                                    })}
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                {CONTACTOS.map((item, index) => {
                                    return (
                                        <Grid item container lg={3} sm={3} xs={12}
                                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 1 : 0}}>
                                            <item.icono sx={{fill: '#fff', marginTop: -0.2}}/>
                                            <Typography sx={{color: '#fff', fontSize: 14, fontWeight: 400}}>
                                                {item.descripcion}
                                            </Typography>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'center', backgroundColor: '#01251F', py: 2}}>
                <Typography sx={{color: '#fff', fontWeight: 300, textAlign: 'center', lineHeight: 1.1}}>Derechos
                    reservados © 2024 | 2025 <br/>
                    <span style={{fontSize: 12}}>0.1.0</span> </Typography>
            </Grid>


        </Grid>
    )

}
export default Footer