import {useDialogo} from "../../Modulo_Dialogos_2025/Hooks/useDialogo";
import Dialogo_Chat_WhatsApp from "../Dialogos/Dialogo_Chat_WhatsApp";
import {marcarEvento} from "../../Servicios/Analiticas/marcarEvento";

export const useDialogoWhatsApp = () => {
    const {Dialogo, abrir: abrirLocal} = useDialogo({Componente: Dialogo_Chat_WhatsApp})

    const abrir = () => {
        marcarEvento('boton_wass')
        abrirLocal()
    }

    return {
        Dialogo,
        abrir
    }

}